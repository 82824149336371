type TTiles = {
	img: string
	alt: string
	text: string
}[]

export default function Tiles({ tiles }: { tiles: TTiles }) {
	return (
		<div className="grid grid-flow-col grid-rows-2 justify-evenly gap-y-8 text-sm font-medium leading-5 text-slate-700 lg:grid-rows-1">
			{tiles.map(tile => (
				<div
					key={tile.text}
					className="flex items-start justify-center gap-2 rounded-2xl p-6 xs:bg-primary/10"
				>
					<img src={tile.img} alt={tile.alt} className="mt-1 w-5" />
					<div>
						{tile.text.split('\n').map(text => (
							<div key={text}>
								{text}
								<br />
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	)
}
